const state = {
  application: {},
  portfolioJson: {},
  currentPage: {},
  openSloth: false,
  overlay: true,
  fromLA: false,
  redirectUrl: ''
}

const getters = {
  getApplication: (state) => state.application,
  getPortfolioJson: state => state.portfolioJson,
  getCurrentPage: (state) => state.currentPage,
  getOpenSloth: (state) => state.openSloth,
  getOverlay: (state) => state.overlay,
  getFromLA: (state) => state.fromLA,
  getRedirectUrl: (state) => state.redirectUrl
}

const actions = {}

const mutations = {
  setPortfolioJson (state, data) {
    state.portfolioJson = data
  },
  setApplication (state, application) {
    state.application = Object.assign(state.application, application)
  },
  setCurrentPage (state, data) {
    state.currentPage = data
  },
  clearApplication (state) {
    state.application = {}
  },
  setOpenSloth (state, data) {
    state.openSloth = data
  },
  clearPageCount (state) {
    state.pageCount = ''
  },
  setOverlay (state, data) {
    state.overlay = data
  },
  setFromLA (state, data) {
    state.fromLA = data
  },
  setRedirectUrl (state, data) {
    state.redirectUrl = data
  }
}

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations
}
