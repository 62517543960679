const state = {
  sloth: null
}

const getters = {
  getSloth: (state) => state.sloth
}

const actions = {}

const mutations = {
  setSloth (state, data) {
    state.sloth = data
  }
}

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations
}
