import { ajax } from '@/js/extension'
import { Tool } from '@/js/core'

const tool = new Tool()
const BASE_URL = '/gateway/platform-lmsapi'

const OriginationApi = {
  // region
  getLoanIdDecrypt (request, success, failure) {
    return ajax.post(BASE_URL + '/ailurus/decrypt', request, success, failure)
  },
  loanMarketing (dataStr, success, failure) {
    return ajax.post(BASE_URL + '/ailurus/loan-marketing', dataStr, success, failure)
  },
  pageControl (request, success, failure) {
    return ajax.post(BASE_URL + '/ailurus/page-control', request, success, failure)
  },
  doEncrypt (request, success, failure) {
    return ajax.post(BASE_URL + '/ailurus/encrypt', request, success, failure)
  },
  getPersonalDataByLoanId (request, success, failure) {
    const loanId = request.loanId
    if (tool.isEmpty(loanId)) {
      return false
    }
    return ajax.get(BASE_URL + '/origination/personal/get', request, success, failure)
  },
  getLoanById (request, success, failure) {
    return ajax.post(BASE_URL + '/origination/loan/get', request, success, failure)
  },
  updateLoanIbvData (request, success, failure) {
    return ajax.put(BASE_URL + '/origination/ibv/update', request, success, failure)
  },
  verifyInformation (request, success, failure) {
    return ajax.post('/gateway/origination-restapi/ailurus/verify', request, success, failure)
  },
  generatePaymentPlanByLoanId (request, success, failure) {
    const loanId = request.loanId
    const portfolioId = request.portfolioId
    if (tool.isEmpty(loanId) || tool.isEmpty(portfolioId)) {
      return false
    }
    return ajax.get(BASE_URL + '/ailurus/generate-payment-plan', request, success, failure)
  },
  blackLinkSignature (input, success, failure) {
    return ajax.post(BASE_URL + '/ailurus/signature', input, success, failure)
  },
  updateOrigination (input, success, failure) {
    return ajax.put(BASE_URL + '/ailurus/update', input, success, failure)
  },
  updateCustomerOptIn (input, success, failure) {
    return ajax.put(BASE_URL + '/customer/opt-in/update', input, success, failure)
  },
  getCustomerOptIn (input, success, failure) {
    return ajax.get(BASE_URL + '/customer/opt-in/list', input, success, failure)
  },
  initSlaveLoan (data, success, failure) {
    return ajax.post(BASE_URL + '/origination-generation/slave-loan/initialize', data, success, failure)
  },
  updateOnlineStep (input, success, failure) {
    return ajax.put(BASE_URL + '/ailurus/online-step/update', input, success, failure)
  },
  calculateLoanAmount (input, success, failure) {
    return ajax.post(BASE_URL + '/ailurus/calculate-returning-loan-amount', input, success, failure)
  },
  checkPersonalState (dataStr, success, failure) {
    return ajax.post(BASE_URL + '/ailurus/check/bank-info', dataStr, success, failure)
  },
  getIp (success) {
    return ajax.get(BASE_URL + '/ailurus/ip-info/get', null, success, null)
  },
  enteredAndLeft (loanId, left, entered, portfolioId) {
    this.updateOnlineStep({
      loanId: loanId,
      left: left,
      entered: entered,
      portfolioId: portfolioId,
      userAgent: window.navigator.userAgent,
      ip: localStorage.getItem('IP')
    })
  },
  getConsentData (input, success, failure) {
    return ajax.get(BASE_URL + '/consent-data/list', input, success, failure)
  },
  saveConsentData (input, success, failure) {
    return ajax.post(BASE_URL + '/consent-data/save', input, success, failure)
  },
  getonsentByLoanId (input, success, failure) {
    return ajax.get(BASE_URL + '/consent-data/list-for-on-line', input, success, failure)
  },
  getProvider (input, success, failure) {
    return ajax.get(BASE_URL + '/ailurus/allocate-ibv-provider', input, success, failure)
  }
}
export { OriginationApi }
