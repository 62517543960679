import { Tool } from '../../../src/js/core'
const tool = new Tool()

const validation = {
  email: {
    validate: value => /^\w+([-+.]\w+)*@\w+([-.]\w+)*$/.test(value)
  },
  notEmpty: {
    validate: value => {
      return (typeof value === 'string' && value.length > 0) || typeof value === 'number'
    }
  },
  hasFile: {
    validate: value => tool.isNotEmpty(value.name),
    getMessage: () => 'Choose a file !'
  },
  csvOrPngFile: {
    validate: value => tool.isNotEmpty(value.name) && (value.name.split('.')[1] === 'csv' || value.name.split('.')[1] === 'png'),
    getMessage: () => 'Choose CSV or png file !'
  },
  min3: {
    validate: value => value.length >= 3
  },
  min6: {
    validate: value => value.length >= 6
  },
  phone: {
    validate: value => {
      const regPhone = /^\d{10,}$/
      value = value.replace(/[^0-9]/ig, '')
      return tool.isNotEmpty(value) && regPhone.test(value)
    },
    getMessage: () => 'Invalid phone number, should be 10 digits'
  },
  fax: {
    validate: value => /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(value)
  },
  number: {
    validate: value => /^(-|\+)?\d+(\.\d+)?$/.test(value),
    getMessage: () => 'Should be a digit'
  },
  positiveInteger: {
    validate: value => /^\d*$/.test(value),
    getMessage: () => 'Should be a positive digit'
  },
  currency: {
    validate: value => {
      const regCurrency = /^(-*[0-9]+[\d]*(.[0-9]{1,2})?)$/
      return tool.isNotEmpty(value) && regCurrency.test(value)
    },
    getMessage: () => 'Invalid Currency format, should be a number'
  },
  positiveCurrency: {
    validate: value => {
      const regPositiveCurrency = /^([0-9]+[\d]*(.[0-9]{1,2})?)$/
      return tool.isNotEmpty(value) && regPositiveCurrency.test(value)
    },
    getMessage: () => 'Invalid Positive Currency format, should be a positive number'
  },
  negativeCurrency: {
    validate: value => {
      const regNegativeCurrency = /^(-[0-9]+[\d]*(.[0-9]{1,2})?)$/
      return tool.isNotEmpty(value) && regNegativeCurrency.test(value)
    },
    getMessage: () => 'Invalid Negative Currency format, should be a negative number'
  },
  moreThanZero: {
    validate: value => parseFloat(value) >= 0
  },
  specialCharacters: {
    validate: value => {
      const regSpecialCharacters = /[-!_@#$%,*:\\/'"+`~^]/im
      return tool.isNotEmpty(value) && !regSpecialCharacters.test(value)
    },
    getMessage: (field) => `${field} found special character`
  },
  bankRoutingNo: {
    validate: value => {
      const regBankRoutingNo = /^\d{9}$/
      return tool.isNotEmpty(value) && regBankRoutingNo.test(value)
    },
    getMessage: (field) => `${field} should be 9 digits`
  },
  bankAccountNo: {
    validate: value => {
      const regBankAccountNo = /^\d*$/
      return tool.isNotEmpty(value) && regBankAccountNo.test(value)
    },
    getMessage: (field) => `${field} should be a digit`
  },
  ssn: {
    validate: value => {
      const regSSN = /^\d{9}$/
      value = value.replace(/[^0-9]/ig, '')
      return tool.isNotEmpty(value) && regSSN.test(value)
    },
    getMessage: (field) => `${field} should be 9 digits`
  },
  zip: {
    validate: value => {
      const regZipCode = /^\d{5}$/
      return tool.isNotEmpty(value) && regZipCode.test(value)
    },
    getMessage: (field) => `${field} should be 5 digits`
  }
}

export { validation }
