import swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

export default {
  install: (Vue) => {
    // efault config
    swal.mixin({
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'rgb(140,212,245)',
      cancelButtonColor: 'rgb(193,193,193)'
    })

    // Add Global Method
    Vue.swal = swal
    // Add Install Method
    Vue.prototype.$swal = swal
  }
}
