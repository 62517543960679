import Vue from 'vue'
import VueRouter from 'vue-router'
import {
  monkeyPages,
  ailurusPCPages, ailurusPages, errorPages, RedirectPages, RootPages,
  AssistPages, AssistSuccessPages, Pages, AuthPages, consentPages, SimulatorPage
} from './config'

Vue.use(VueRouter)

const routes = [
  monkeyPages,
  ailurusPCPages,
  ailurusPages,
  /* original */
  Pages,
  errorPages,
  RedirectPages,
  RootPages,
  AssistPages,
  AssistSuccessPages,
  AuthPages,
  consentPages,
  SimulatorPage
]

const router = new VueRouter({ routes })
/* 路由拦截 */
router.beforeEach((to, from, next) => {
  switch (process.env.NODE_ENV) {
    case 'develop':
      next()
      break
    case 'production':
      if (to.path === '/ailurus/leads' || to.path === '/simulator/:uuid') {
        next('/auth')
      } else {
        next()
      }
      break
    default :
      next()
      break
  }
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch((err) => err)
}
export default router
