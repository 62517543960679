import Vue from 'vue'
import Vuex from 'vuex'
import application from './modules/application'
import slothStore from './modules/slothStore'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    application,
    slothStore
  },
  plugins: [createPersistedState({
    // 需要持久化的模块
    paths: ['application']
  })]
})

export default store
