<template>
  <div>
    <v-toolbar style="background-color: #8FBAEC; margin-top: 20px" elevation="0">
      <a
        style="position: absolute; left: 0px"
        :href="toHttp(portfolio.website)" target="_blank">
        <img
          :src="portfolio.logo"
          class="height-60"
        />
      </a>
      <v-spacer></v-spacer>
      <progress-step v-if="showPageStep" :step="page.step"></progress-step>
      <v-spacer></v-spacer>
    </v-toolbar>
  </div>
</template>
<script>
import { Tool, DataType } from '../js/core'
import ProgressStep from './progress/ProgressStepPC'

const tool = new Tool()
export default {
  name: 'PCAppToolbar',
  components: { ProgressStep },
  data () {
    return {
      showBack: true,
      showPageStep: true
    }
  },
  computed: {
    portfolio: {
      get () {
        return this.$store.state.application.portfolioJson
      }
    },
    portfolioTelephone: {
      get () {
        return 'tel:' + this.$store.state.application.portfolioJson.telephone
      }
    },
    page: {
      get () {
        return this.$store.state.application.currentPage
      }
    }
  },
  watch: {
    'store.getters.getFromLA': {
      handler (val) {
        this.showPageStep = !val
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    toHttp (url) {
      if (url) {
        if (!url.includes('https://')) {
          return 'https://' + url
        } else {
          return url
        }
      }
    },
    goBack () {
      this.$router.push(DataType.PAGES[this.page.previous].address)
    }
  },
  created () {
    if (tool.isEmpty(this.$store.state.application.portfolioJson) && tool.isNotEmpty(localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO))) {
      this.$store.commit('setPortfolioJson', JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO)))
    }
  }
}
</script>
