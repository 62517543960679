import { DefaultLayout, PCDefaultLayout, Layout } from '../components/layouts'

export const Pages = {
  path: '/redirect/:uuid',
  name: 'Root',
  props: true,
  component: () => import(/* Redirect */ '@/views/Root.vue')
}

export const SimulatorPage = {
  path: '/simulator/:uuid',
  name: 'Simulator',
  component: () => import('@/views/Simulator.vue')
}

export const RootPages = {
  path: '/',
  props: true,
  redirect: '/auth'
}

export const RedirectPages = {
  path: '/auth',
  name: 'Redirect',
  props: true,
  component: () => import(/* Redirect */ '@/views/Redirect.vue')
}

export const consentPages = {
  path: '/signature/:uuid',
  name: 'Consent',
  component: () => import(/* Redirect */ '@/views/Consent.vue')
}

export const AssistPages = {
  path: '/assist/instant-card/:uuid?',
  name: 'InstantCard',
  props: true,
  component: () => import(/* Redirect */ '@/views/assist/InstantCard.vue')
}

export const AuthPages = {
  path: '/verify',
  props: true,
  component: () => import('@/views/assist/Auth.vue')
}

export const AssistSuccessPages = {
  path: '/assist/success',
  name: 'Success',
  meta: {
    isIntercept: true
  },
  component: () => import(/* Authentication */ '@/views/assist/Success.vue')
}

export const ailurusPages = {
  path: '/authentication',
  name: 'Ailurus',
  component: DefaultLayout,
  children: [
    {
      path: '/authentication/instant-card',
      name: 'InstantCard',
      meta: {
        isIntercept: true
      },
      component: () => import(/* Authentication */ '@/views/wap/InstantCard.vue')
    },
    {
      path: '/authentication/transaction',
      name: 'Transaction',
      meta: {
        isIntercept: true
      },
      component: () => import(/* Authentication */ '@/views/wap/Transaction.vue')
    },
    // {
    //   path: '/authentication/welcome',
    //   name: 'Welcome',
    //   meta: {
    //     isIntercept: true
    //   },
    //   component: () => import(/* Welcome */ '@/views/wap/Welcome.vue')
    // },
    {
      path: '/notification/:errorCode?',
      name: 'Notification',
      props: true,
      meta: {
        isIntercept: false
      },
      component: () => import(/* Notification */ '@/views/wap/Notification.vue')
    },
    {
      path: '/authentication/military',
      name: 'Military',
      meta: {
        isIntercept: true
      },
      component: () => import(/* Authentication */ '@/views/wap/Military.vue')
    },
    {
      path: '/authentication/authenticate',
      name: 'Authenticate',
      meta: {
        isIntercept: true
      },
      component: () => import(/* Authentication */ '@/views/wap/Authenticate.vue')
    },
    {
      path: '/authentication/send-code',
      name: 'SendCode',
      meta: {
        isIntercept: true
      },
      component: () => import(/* Authentication */ '@/views/wap/SendCode.vue')
    },
    // {
    //   path: '/authentication/basic-info',
    //   name: 'BasicInfo',
    //   meta: {
    //     isIntercept: true
    //   },
    //   component: () => import(/* Authentication */ '@/views/wap/BasicInfo.vue')
    // },
    // {
    //   path: '/authentication/profile',
    //   name: 'PersonalVerify',
    //   meta: {
    //     isIntercept: true
    //   },
    //   component: () => import(/* Authentication */ '@/views/wap/Profile.vue')
    // },
    {
      path: '/authentication/employment',
      name: 'Employment',
      meta: {
        isIntercept: true
      },
      component: () => import(/* Authentication */ '@/views/wap/Employment.vue')
    },
    {
      path: '/authentication/bank',
      name: 'Bank',
      meta: {
        isIntercept: true
      },
      component: () => import(/* Authentication */ '@/views/wap/Bank.vue')
    },
    // {
    //   path: '/authentication/dl-information',
    //   name: 'DL_Information',
    //   meta: {
    //     isIntercept: true
    //   },
    //   component: () => import(/* Authentication */ '@/views/wap/DLInformation.vue')
    // },
    {
      path: '/authentication/verification',
      name: 'Audit',
      meta: {
        isIntercept: true
      },
      component: () => import(/* Authentication */ '@/views/wap/Verification.vue')
    },
    {
      path: '/authentication/option',
      name: 'LoanAmount',
      meta: {
        isIntercept: true
      },
      component: () => import(/* Authentication */ '@/views/wap/Option.vue')
    },
    {
      path: '/authentication/loan-information',
      name: 'LoanInformation',
      meta: {
        isIntercept: true
      },
      component: () => import(/* Authentication */ '@/views/wap/LoanInformation.vue')
    },
    {
      path: '/authentication/signature',
      name: 'Signature',
      meta: {
        isIntercept: false
      },
      component: () => import(/* Authentication */ '@/views/wap/Signature.vue')
    },
    {
      path: '/authentication/congratulate',
      name: 'Congratulate',
      meta: {
        isIntercept: false
      },
      component: () => import(/* Authentication */ '@/views/wap/Congratulate.vue')
    },
    // {
    //   path: '/authentication/decision-logic',
    //   name: 'DecisionLogic',
    //   meta: {
    //     isIntercept: true
    //   },
    //   component: () => import(/* Authentication */ '@/views/wap/DecisionLogic.vue')
    // },
    {
      path: '/authentication/advertising',
      name: 'Advertising',
      meta: {
        isIntercept: true
      },
      component: () => import(/* Authentication */ '@/views/wap/Advertising.vue')
    },
    {
      path: '/authentication/communication',
      name: 'Communication',
      meta: {
        isIntercept: true
      },
      component: () => import(/* Authentication */ '@/views/wap/Communication.vue')
    }
  ]
}

export const ailurusPCPages = {
  path: '/wap',
  name: 'Ailurus',
  component: PCDefaultLayout,
  children: [
    {
      path: '/instant/instant-card',
      name: 'InstantCard',
      meta: {
        isIntercept: true
      },
      component: () => import(/* Authentication */ '@/views/web/InstantCard.vue')
    },
    {
      path: '/instant/transaction',
      name: 'Transaction',
      meta: {
        isIntercept: true
      },
      component: () => import(/* Authentication */ '@/views/web/Transaction.vue')
    },
    // {
    //   path: '/instant/welcome',
    //   name: 'Welcome',
    //   meta: {
    //     isIntercept: true
    //   },
    //   component: () => import(/* Welcome */ '@/views/web/Welcome.vue')
    // },
    // {
    //   path: '/instant/basic-info',
    //   name: 'BasicInfo',
    //   meta: {
    //     isIntercept: true
    //   },
    //   component: () => import(/* Authentication */ '@/views/web/BasicInfo.vue')
    // },
    {
      path: '/instant/notification/:errorCode?',
      name: 'Notification',
      props: true,
      meta: {
        isIntercept: false
      },
      component: () => import(/* Notification */ '@/views/web/Notification.vue')
    },
    {
      path: '/instant/military',
      name: 'Military',
      meta: {
        isIntercept: true
      },
      component: () => import(/* Authentication */ '@/views/web/Military.vue')
    },
    {
      path: '/instant/authenticate',
      name: 'Authenticate',
      meta: {
        isIntercept: true
      },
      component: () => import(/* Authentication */ '@/views/web/Authenticate.vue')
    },
    {
      path: '/instant/send-code',
      name: 'SendCode',
      meta: {
        isIntercept: true
      },
      component: () => import(/* Authentication */ '@/views/web/SendCode.vue')
    },
    // {
    //   path: '/instant/profile',
    //   name: 'PersonalVerify',
    //   meta: {
    //     isIntercept: true
    //   },
    //   component: () => import(/* Authentication */ '@/views/web/Profile.vue')
    // },
    {
      path: '/instant/employment',
      name: 'Employment',
      meta: {
        isIntercept: true
      },
      component: () => import(/* Authentication */ '@/views/web/Employment.vue')
    },
    {
      path: '/instant/bank',
      name: 'Bank',
      meta: {
        isIntercept: true
      },
      component: () => import(/* Authentication */ '@/views/web/Bank.vue')
    },
    // {
    //   path: '/instant/dl-information',
    //   name: 'DL_Information',
    //   meta: {
    //     isIntercept: true
    //   },
    //   component: () => import(/* Authentication */ '@/views/web/DLInformation.vue')
    // },
    {
      path: '/instant/verification',
      name: 'Audit',
      meta: {
        isIntercept: true
      },
      component: () => import(/* Authentication */ '@/views/web/Verification.vue')
    },
    {
      path: '/instant/option',
      name: 'LoanAmount',
      meta: {
        isIntercept: true
      },
      component: () => import(/* Authentication */ '@/views/web/Option.vue')
    },
    {
      path: '/instant/loan-information',
      name: 'LoanInformation',
      meta: {
        isIntercept: true
      },
      component: () => import(/* Authentication */ '@/views/web/LoanInformation.vue')
    },
    {
      path: '/instant/signature',
      name: 'Signature',
      meta: {
        isIntercept: false
      },
      component: () => import(/* Authentication */ '@/views/web/Signature.vue')
    },
    {
      path: '/instant/congratulate',
      name: 'Congratulate',
      meta: {
        isIntercept: false
      },
      component: () => import(/* Authentication */ '@/views/web/Congratulate.vue')
    },
    // {
    //   path: '/instant/decision-logic',
    //   name: 'DecisionLogic',
    //   meta: {
    //     isIntercept: true
    //   },
    //   component: () => import(/* Authentication */ '@/views/web/DecisionLogic.vue')
    // },
    {
      path: '/instant/advertising',
      name: 'Advertising',
      meta: {
        isIntercept: true
      },
      component: () => import(/* Authentication */ '@/views/web/Advertising.vue')
    },
    {
      path: '/instant/communication',
      name: 'Communication',
      meta: {
        isIntercept: true
      },
      component: () => import(/* Authentication */ '@/views/web/Communication.vue')
    }
  ]
}

export const monkeyPages = {
  path: 'monkey',
  name: 'Monkey',
  component: Layout,
  children: [
    {
      path: '/instant/welcome',
      name: 'Welcome',
      meta: {
        isIntercept: true
      },
      component: () => import(/* Monkey */ '@/views/common/Welcome.vue')
    },
    {
      path: '/instant/dl-information',
      name: 'DL_Information',
      meta: {
        isIntercept: true
      },
      component: () => import(/* Monkey */ '@/views/common/DLInformation.vue')
    },
    {
      path: '/instant/decision-logic',
      name: 'DecisionLogic',
      meta: {
        isIntercept: true
      },
      component: () => import(/* Monkey */ '@/views/common/DecisionLogic.vue')
    },
    {
      path: '/instant/notification/:errorCode?',
      name: 'Notification',
      props: true,
      meta: {
        isIntercept: false
      },
      component: () => import(/* Notification */ '@/views/common/Notification.vue')
    },
    {
      path: '/instant/profile',
      name: 'PersonalVerify',
      meta: {
        isIntercept: true
      },
      component: () => import(/* Monkey */ '@/views/common/Profile.vue')
    },
    {
      path: '/instant/basic-info',
      name: 'BasicInfo',
      meta: {
        isIntercept: true
      },
      component: () => import(/* Monkey */ '@/views/common/BasicInfo.vue')
    }
  ]
}

/*          original           */
export const errorPages = {
  path: '/error',
  component: DefaultLayout,
  meta: {
    title: 'Error Pages',
    group: 'apps',
    icon: '',
    isIntercept: true
  },
  redirect: '/error',
  children: [
    {
      path: '/error/403',
      name: 'Deny',
      meta: {
        isIntercept: true
      },
      component: () => import(/* webpackChunkName: "error-403" */ '@/views/error/Deny.vue')
    },
    {
      path: '/error/404',
      name: 'NotFound',
      meta: {
        isIntercept: false
      },
      component: () => import(/* webpackChunkName: "error-403" */ '@/views/error/NotFound.vue')
    },
    {
      path: '/error/500',
      name: 'Error',
      meta: {
        isIntercept: false
      },
      component: () => import(/* webpackChunkName: "error-403" */ '@/views/error/Error.vue')
    }
  ]
}
