<template>
  <v-app :dark="true">
    <router-view></router-view>
    <!-- global snackbar -->
    <v-snackbar :timeout="3000" bottom right :color="snackbar.color" v-model="snackbar.show">
      {{ snackbar.text }}
      <v-btn dark text @click.native="snackbar.show = false" icon>
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>

export default {
  components: {},
  data () {
    return {
      rightDrawer: false,
      snackbar: {
        show: false,
        text: '',
        color: ''
      }
    }
  },
  watch: {
    'store.getters.getPortfolioJson' (val) {
      if (val) {
        this.addOlark()
      }
    }
  },
  mounted () {},
  created () {
    // add app events
  },
  methods: {
    addOlark () {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.text =
        ';(function(o,l,a,r,k,y){if(o.olark)return;\n' +
        '      r="script";y=l.createElement(r);r=l.getElementsByTagName(r)[0];\n' +
        '      y.async=1;y.src="//"+a;r.parentNode.insertBefore(y,r);\n' +
        '      y=o.olark=function(){k.s.push(arguments);k.t.push(+new Date)};\n' +
        '      y.extend=function(i,j){y("extend",i,j)};\n' +
        '      y.identify=function(i){y("identify",k.i=i)};\n' +
        '      y.configure=function(i,j){y("configure",i,j);k.c[i]=j};\n' +
        '      k=y._={s:[],t:[+new Date],c:{},l:a};\n' +
        '    })(window,document,"static.olark.com/jsclient/loader.js");\n' +
        '    /* Add configuration calls below this comment */\n' +
        "    const portfolio = JSON.parse(window.localStorage.getItem('PORTFOLIO'))\n" +
        '    if (portfolio) {\n' +
        '      olark.identify(portfolio.olarkSiteId);\n' +
        '    }'
      script.async = true
      document.body.appendChild(script)
    }
  }
}
</script>

<style lang="sass" scoped>
.setting-fab
  top: 50% !important
  right: 0
  border-radius: 0
</style>
