<template>
  <v-app>
    <app-toolbar></app-toolbar>
    <v-main>
      <router-view :sloth="sloth" />
    </v-main>

    <v-snackbar
      color="primary"
      v-model="showModal"
      top
      timeout="-1"
    >
      Congratulations! Our Pre-Underwriting Department has finalized the review of your application. To proceed with your {{loanAmount}} loan, simply click on the icon below.
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="toLoanAmount"
        >
          {{ times }}s
        </v-btn>
      </template>
    </v-snackbar>
<!--    <v-snackbar dark v-model="snackbar" timeout="-1">-->
<!--      <v-card elevation="5" class="rounded-xl">-->
<!--        <v-card-title class="text-center">-->
<!--          <h3 class="f-w-700 width-full">Facts and Tips</h3>-->
<!--        </v-card-title>-->
<!--        <v-card-text>-->
<!--          Dear {{ firstName }}, did you know that many lenders base on your credit score to determine whether you're-->
<!--          pre-qualified for a loan or not? we're not saying that we do, but many other lenders do, Hold on and we will-->
<!--          redirect you to the right people.-->
<!--        </v-card-text>-->
<!--      </v-card>-->
<!--      <v-card flat class="m-t-20" color="transparent">-->
<!--        <v-card-text>-->
<!--          <div-->
<!--            style="display: flex; justify-content: center; align-items: center; font-size: 16px; width: 50px; height: 50px; border-radius: 50px; border: 1px solid rgba(255, 255, 255, 0.12); margin: 0 auto;">-->
<!--            {{timer}}-->
<!--          </div>-->
<!--        </v-card-text>-->
<!--        <v-card-text class="text-center">-->
<!--          We are redirecting you to New Image Relief, wanna skip the timer?-->
<!--          click below.-->
<!--        </v-card-text>-->
<!--        <v-card-actions>-->
<!--          <v-card-text class="text-center">-->
<!--            <v-row>-->
<!--              <v-col>-->
<!--                <u @click="closeSnackbar">No thanks</u>-->
<!--              </v-col>-->
<!--              <v-col>-->
<!--&lt;!&ndash;                <a href="https://newimagerelief.com/why-you-should-review-your-credit-report" style="color: inherit;">Get Redirected Now!</a>&ndash;&gt;-->
<!--              </v-col>-->
<!--            </v-row>-->
<!--          </v-card-text>-->
<!--        </v-card-actions>-->
<!--      </v-card>-->
<!--    </v-snackbar>-->
  </v-app>
</template>

<script>
import AppToolbar from '../AppToolbar'
import { DataType, Tool } from '../../js/core'
import { Sloth } from '../../js/sloth'
import { OriginationApi } from '../../api/application'

const tool = new Tool()

export default {
  components: {
    AppToolbar
  },

  data () {
    return {
      times: 10,
      showModal: false,
      loanAmount: '',
      options: {
        mode: 'ClientSide',
        url: '',
        wsid: ''
      },
      rightDrawer: false,
      sloth: {
        display: false,
        client: null
      },
      interval: null,
      firstName: JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PERSONAL)).firstName,
      timer: 10,
      snackbar: false,
      dialogShowTime: 0,
      timeOutTimer: 0,
      dialogClose: 0
    }
  },
  destroyed () {
    this.$eventBus.$off('showLoanAmountDialog')
    this.$eventBus.$off('verification')
    this.$eventBus.$off('startChat')
  },
  methods: {
    closeSnackbar () {
      const $this = this
      $this.snackbar = false
      window.clearInterval(this.timeOutTimer)
      window.clearInterval(this.dialogClose)
    },
    countDown () {
      const $this = this
      $this.interval = setInterval(function () {
        if ($this.times > 0) {
          --$this.times
        } else {
          clearInterval($this.interval)
          $this.toLoanAmount()
        }
      }, 1000)
    },
    toLoanAmount () {
      this.showModal = false
      clearInterval(this.interval)
      this.$router.push(DataType.PAGES.LOAN_AMOUNT.address)
    },
    initWebSocket () {
      const $this = this
      const wsid = localStorage.getItem(DataType.COOKIE_KEY.WSID)
      if (tool.isNotEmpty(wsid)) {
        this.options.wsid = wsid
        this.sloth.client = new Sloth(this.options)
        this.$eventBus.$emit('initSloth')
        this.sloth.client.init()
        this.sloth.client.on('message', function (message) {
          message = message.data
          switch (parseInt(message.ticket)) {
            case DataType.LoanActionEnum.FORCE_QUIT.value: {
              $this.$router.push('/notification/-100')
              $this.$eventBus.$emit('forceQuit', 'forceQuit')
              break
            }
            default: {
              break
            }
          }
        })
      }
    },
    initTimeOut () {
      const $this = this
      const portfolioId = localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
      const page = $this.$store.state.application.currentPage
      if (tool.isEmpty(page.popup) || !page.popup) {
        return false
      }
      if (tool.isEmpty(portfolioId) ||
        DataType.PortfolioEnum.WEST_SIDE_LENDING.value === parseInt(portfolioId) ||
        DataType.PortfolioEnum.EAST_LINE_LENDING.value === parseInt(portfolioId)
      ) {
        return false
      }
      $this.dialogShowTime = 5 * 60
      document.body.addEventListener('keydown', function () {
        $this.dialogShowTime = 5 * 60
      })
      document.body.addEventListener('mousedown', function () {
        $this.dialogShowTime = 5 * 60
      })
      $this.timeOutTimer = window.setInterval(function () {
        if ($this.dialogShowTime > 0) {
          $this.dialogShowTime -= 1
        } else {
          window.clearInterval($this.timeOutTimer)
          $this.timer = 10
          $this.snackbar = true
          $this.dialogClose = setInterval(function () {
            window.clearInterval($this.timeOutTimer)
            if ($this.timer > 0) {
              $this.timer -= 1
            } else {
              window.clearInterval($this.dialogClose)
              $this.snackbar = false
              // window.location.href = 'https://newimagerelief.com/why-you-should-review-your-credit-report'
            }
          }, 1000)
        }
      }, 1000)
    }
  },
  created () {
    const _this = this
    // setTimeout(function () {
    //   _this.initTimeOut()
    // }, 500)
    const ip = localStorage.getItem('IP')
    if (tool.isEmpty(ip)) {
      OriginationApi.getIp(function (result) {
        localStorage.setItem('IP', result.ip)
      })
    }
    if (tool.isPC()) {
      setTimeout(function () {
        const page = _this.$store.state.application.currentPage
        _this.$router.push(page.addressPC)
      }, 300)
    }
    const protocol = window.location.protocol === 'http:'
    if (protocol) {
      this.options.url = process.env.VUE_APP_WSXCH_URL
    } else {
      this.options.url = process.env.VUE_APP_WSXCH_DNS
    }
    this.initWebSocket()
    this.$eventBus.$on('showLoanAmountDialog', (loanAmount) => {
      if (this.$store.state.application.pageCount < 7) {
        this.loanAmount = loanAmount
        this.showModal = true
        this.countDown()
      }
    })
    this.$eventBus.$on('verification', () => {
      this.initWebSocket()
    })
    this.$vuetify.theme.themes.light.primary = '#00234B'
    const $this = this
    $this.$eventBus.$on('closeCountDown', function () {
      window.clearInterval($this.timeOutTimer)
    })
    // $this.$eventBus.$on('initTimeOut', () => {
    //   window.clearInterval($this.timeOutTimer)
    //   $this.initTimeOut()
    // })
  }
}
</script>

<style lang="sass">
  html, body
    height: 100%
  body
    background-color: #F9FBFE !important
  .v-application, .v-main
    height: 100%
</style>
