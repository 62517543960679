<template>
  <div class="progress-step-container">
    <div>
      <div class="my-2 float-right">
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :color=personal
              fab
              x-small
              dark
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </template>
          <span>Client Information</span>
        </v-tooltip>
      </div>
    </div>
    <v-progress-linear
      style="margin-top: 23px;"
      buffer-value="0"
      stream
      :color="bank"
    ></v-progress-linear>
    <div>
      <div class="my-2  float-right">
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :color=bank
              fab
              x-small
              dark
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-bank</v-icon>
            </v-btn>
          </template>
          <span>Bank</span>
        </v-tooltip>
      </div>
    </div>

    <v-progress-linear
      style="margin-top: 23px;"
      buffer-value="0"
      stream
      :color="signature"
    ></v-progress-linear>
    <div >
      <div class="my-2  float-right">
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :color="signature"
              fab
              x-small
              dark
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Signature</span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'progress-step',
  props: {
    step: Number
  },
  data () {
    return {
      signature: 'grey',
      bank: 'grey',
      personal: 'primary'
    }
  },
  created () {
    if (this.step > 200 && this.step < 300) {
      this.bank = 'primary'
    }
    if (this.step >= 300) {
      this.bank = 'primary'
      this.signature = 'primary'
    }
  }
}
</script>

<style lang="sass" scoped>
  .progress-step-container
    display: flex
    justify-content: center
    align-content: center
    padding: 0 16px
</style>
