<template>
  <div class="m-r-10 m-l-10"
       style="
        display: flex;
        width: 40%;
        justify-content: center;
        align-content: center
      "
  >
    <div>
      <div class="my-2 float-right">
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :color=personal
              fab
              outlined
              x-small
              dark
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </template>
          <span>Client Information</span>
        </v-tooltip>
      </div>
    </div>
    <v-progress-linear
      style="margin-top: 23px;"
      buffer-value="0"
      stream
      color="grey"
    ></v-progress-linear>
    <div>
      <div class="my-2 float-right">
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :color=employment
              fab
              outlined
              x-small
              dark
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-account-group</v-icon>
            </v-btn>
          </template>
          <span>Client Information</span>
        </v-tooltip>
      </div>
    </div>
    <v-progress-linear
      style="margin-top: 23px;"
      buffer-value="0"
      stream
      color="grey"
    ></v-progress-linear>
    <div>
      <div class="my-2  float-right">
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :color=bank
              fab
              x-small
              outlined
              dark
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-bank</v-icon>
            </v-btn>
          </template>
          <span>Bank</span>
        </v-tooltip>
      </div>
    </div>
    <v-progress-linear
      style="margin-top: 23px;"
      buffer-value="0"
      stream
      color="grey"
    ></v-progress-linear>
    <div>
      <div class="my-2 float-right">
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :color=verify
              fab
              outlined
              x-small
              dark
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-clock-outline</v-icon>
            </v-btn>
          </template>
          <span>Client Information</span>
        </v-tooltip>
      </div>
    </div>
    <v-progress-linear
      style="margin-top: 23px;"
      buffer-value="0"
      stream
      color="grey"
    ></v-progress-linear>
    <div>
      <div class="my-2 float-right">
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :color=check
              fab
              outlined
              x-small
              dark
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-check</v-icon>
            </v-btn>
          </template>
          <span>Client Information</span>
        </v-tooltip>
      </div>
    </div>
    <v-progress-linear
      style="margin-top: 23px;"
      buffer-value="0"
      stream
      color="grey"
    ></v-progress-linear>
    <div>
      <div class="my-2  float-right">
        <v-tooltip
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :color="signature"
              fab
              x-small
              outlined
              dark
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Signature</span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'progress-step',
  props: {
    step: Number
  },
  data () {
    return {
    }
  },
  computed: {
    personal: {
      get () {
        let color = 'grey'
        if (this.step === 101 || this.step === 209) {
          color = ''
        }
        return color
      }
    },
    employment: {
      get () {
        let color = 'grey'
        if (this.step === 102) {
          color = ''
        }
        return color
      }
    },
    bank: {
      get () {
        let color = 'grey'
        if (this.step === 201) {
          color = ''
        }
        return color
      }
    },
    verify: {
      get () {
        let color = 'grey'
        const pages = [220, 202, 203]
        if (pages.includes(this.step)) {
          color = ''
        }
        return color
      }
    },
    check: {
      get () {
        let color = 'grey'
        const pages = [204, 205, 210, 206]
        if (pages.includes(this.step)) {
          color = ''
        }
        return color
      }
    },
    signature: {
      get () {
        let color = 'grey'
        if (this.step === 208) {
          color = ''
        }
        return color
      }
    }
  }
}
</script>

<style lang="css">
  @keyframes stream-ltr {
  transform: none;
  }
</style>
