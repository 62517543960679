<template>
  <v-app>
    <div :class="isPc ? 'PCBg' : ''" v-if="isPc">
      <img class="img-height width-full m-0"
           src="@/assets/background.png"
      />
    </div>
    <div :class="isPc ? 'container-layout-pc' : 'container-layout-mb'">
      <PCAppToolbar v-if="isPc"/>
      <app-toolbar v-if="!isPc" :isPc="isPc" />
      <div :class="isPc ? 'pages-wrapper-pc' : 'pages-wrapper-mb'">
        <v-card class="b-r-16" :class="isPc ? 'container-content-pc' : 'container-content-mb'">
          <ProgressStep :step="step" v-if="!isPc" />
          <v-btn v-if="page.back && isPc"
                 @click="goBack"
                 :class="isPc ? 'pc-back' : 'mb-back'"
                 icon style="position: absolute; top: 20px; left: 120px; z-index: 100">
            <img src="@/assets/icon/back.png"
                 class="height-24"
            />
          </v-btn>
          <router-view :class="isPc ? 'router-position-pc' : 'router-position-mb'" :sloth="sloth" :isPc="isPc"/>
          <a :href="portfolioTelephone" v-if="showPhoneBtn && isPc" :class="isPc ? 'pc-phone' : 'mb-phone'">
            <v-btn icon style="position: absolute; top: 20px; right: 120px">
              <v-icon
                  class="height-24"
              >phone
              </v-icon>
            </v-btn>
          </a>
          <v-overlay
              absolute
              :value="overlay"
          >
            <v-icon class="mdi mdi-spin mdi-loading" large></v-icon>
          </v-overlay>
        </v-card>
      </div>
      <v-snackbar
          color="primary"
          v-model="showModal"
          top
          timeout="-1"
      >
        Congratulations! Our Pre-Underwriting Department has finalized the review of your application. To proceed with
        your {{loanAmount}} loan, simply click on the icon below.
        <template v-slot:action="{ attrs }">
          <v-btn
              text
              v-bind="attrs"
              @click="toLoanAmount"
          >
            {{ times }}s
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-app>
</template>

<script>
import PCAppToolbar from '@/components/PCAppToolbar'
import AppToolbar from '@/components/AppToolbar'
import ProgressStep from '@/components/progress/ProgressStep'
import { DataType, Tool } from '../../js/core'
import { Sloth } from '@/js/sloth'
import { OriginationApi } from '@/api/application'

const tool = new Tool()

export default {
  components: {
    PCAppToolbar,
    AppToolbar,
    ProgressStep
  },

  data () {
    return {
      times: 10,
      showModal: false,
      loanAmount: '',
      showPhoneBtn: true,
      options: {
        mode: 'ClientSide',
        url: '',
        wsid: ''
      },
      sloth: {
        client: null,
        messagesNumber: JSON.parse(sessionStorage.getItem('messageNumber'))
      },
      interval: null,
      firstName: JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PERSONAL))?.firstName,
      timer: 10,
      snackbar: false,
      dialogShowTime: 0,
      timeOutTimer: 0,
      dialogClose: 0,
      screenWidth: 0,
      isPc: ''
    }
  },
  computed: {
    page: {
      get () {
        return this.$store.state.application.currentPage
      }
    },
    locationUrl () {
      return this.$route.path
    },
    overlay: {
      get () {
        let flag = false
        if (tool.isPC()) {
          flag = this.$store.state.application.overlay
        } else {
          flag = false
        }
        return flag
      }
    },
    portfolioTelephone: {
      get () {
        return 'tel:' + this.$store.state.application.portfolioJson.telephone
      }
    },
    step: {
      get () {
        return this.$store.state.application.currentPage.step
      }
    }
  },
  watch: {
    'store.getters.getFromLA': {
      handler (val) {
        this.showPhoneBtn = !val
      },
      immediate: true,
      deep: true
    },
    screenWidth: {
      handler: function (val, oldVal) {
        if (tool.isPC()) {
          this.isPc = true
        } else {
          this.isPc = false
        }
      }
    },
    // 监听当前url，若包含错误页则不显示回退按钮
    locationUrl: {
      handler: function (val, oldVal) {
        if (val.includes('notification')) {
          this.page.back = false
        }
      },
      immediate: true,
      deep: true
    }
  },
  created () {
    const _this = this
    const ip = localStorage.getItem('IP')
    if (tool.isEmpty(ip)) {
      OriginationApi.getIp(function (result) {
        localStorage.setItem('IP', result.ip)
      })
    }
    const protocol = window.location.protocol === 'http:'
    if (protocol) {
      this.options.url = process.env.VUE_APP_WSXCH_URL
    } else {
      this.options.url = process.env.VUE_APP_WSXCH_DNS
    }
    this.initWebSocket()
    this.$eventBus.$on('showLoanAmountDialog', (loanAmount) => {
      if (this.$store.state.application.pageCount < 7) {
        this.loanAmount = loanAmount
        this.showModal = true
        this.countDown()
      }
    })
    this.$eventBus.$on('verification', () => {
      this.initWebSocket()
    })
    _this.$vuetify.theme.themes.light.primary = '#00234B'

    _this.$eventBus.$on('closeCountDown', () => {
      window.clearInterval(_this.dialogShowTime)
      window.clearInterval(_this.timeOutTimer)
    })
  },
  mounted () {
    // 获取屏幕宽度
    this.screenWidth = document.body.clientWidth
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth
      })()
    }
  },
  destroyed () {
    this.$eventBus.$off('showLoanAmountDialog')
    this.$eventBus.$off('verification')
    this.$eventBus.$off('startChat')
  },
  methods: {
    closeSnackbar () {
      const $this = this
      $this.snackbar = false
      window.clearInterval($this.timeOutTimer)
      window.clearInterval($this.dialogClose)
    },
    countDown () {
      const $this = this
      $this.interval = setInterval(function () {
        if ($this.times > 0) {
          --$this.times
        } else {
          clearInterval($this.interval)
          $this.toLoanAmount()
        }
      }, 1000)
    },
    goBack () {
      this.closeSnackbar()
      this.$router.push(DataType.PAGES[this.page.previous].addressPC)
    },
    toLoanAmount () {
      this.showModal = false
      clearInterval(this.interval)
      this.$router.push('/wap/option')
    },
    initWebSocket () {
      const $this = this
      const wsid = localStorage.getItem(DataType.COOKIE_KEY.WSID)
      if (tool.isNotEmpty(wsid)) {
        this.options.wsid = wsid
        this.sloth.client = new Sloth(this.options)
        this.$eventBus.$emit('initSloth')
        this.sloth.client.init()
        this.sloth.client.on('message', function (message) {
          message = message.data
          switch (parseInt(message.ticket)) {
            case DataType.LoanActionEnum.FORCE_QUIT.value: {
              if ($this.isPc) {
                $this.$router.push('/instant/notification/-100')
              } else {
                $this.$router.push('/notification/-100')
              }
              $this.$eventBus.$emit('forceQuit', 'forceQuit')
              break
            }
            default: {
              break
            }
          }
        })
      }
    },
    initTimeOut () {
      const $this = this
      const portfolioId = localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
      const page = $this.$store.state.application.currentPage
      if (tool.isEmpty(page.popup) || !page.popup) {
        return false
      }
      if (tool.isEmpty(portfolioId) ||
          DataType.PortfolioEnum.WEST_SIDE_LENDING.value === parseInt(portfolioId) ||
          DataType.PortfolioEnum.EAST_LINE_LENDING.value === parseInt(portfolioId)
      ) {
        return false
      }
      $this.dialogShowTime = 5 * 60
      document.body.addEventListener('keydown', function () {
        $this.dialogShowTime = 5 * 60
      })
      document.body.addEventListener('mousedown', function () {
        $this.dialogShowTime = 5 * 60
      })
      $this.timeOutTimer = window.setInterval(function () {
        if ($this.dialogShowTime > 0) {
          $this.dialogShowTime -= 1
        } else {
          window.clearInterval($this.timeOutTimer)
          $this.timer = 10
          $this.snackbar = true
          $this.dialogClose = setInterval(function () {
            if ($this.timer > 0) {
              $this.timer -= 1
            } else {
              window.clearInterval($this.dialogClose)
              $this.snackbar = false
            }
          }, 1000)
        }
      }, 1000)
    }
  }
}
</script>

<style lang="sass" scoped>
//PC
.PCBg
  display: block
  width: 100%
  position: absolute
  height: 100%
.img-height
  height: 99vh !important
.container-layout-pc
  width: 75%
  margin: auto
  height: 100vh

.pages-wrapper-pc
  min-height: calc(100vh - 90px)
  padding-top: 20px !important
.container-content-pc
  width: 75%
  position: absolute
  top: 104px
  bottom: 50px
.router-position-pc
  height: 100%
  width: 100%
  position: relative

//Mobile
.container-layout-mb
  width: 100%

.pages-wrapper-mb
  padding-top: 0
  height: 100vh
  width: 100%

.mb-back
  display: none !important

.mb-phone
  display: none !important

.container-content-mb
  border-radius: 0 !important
  position: absolute
  width: 100%
  top: 3.375rem
  bottom: 0

.router-position-mb
  height: auto

</style>
<style lang="css">
/*public*/
.v-toolbar__content, .v-toolbar__extension {
  padding: 0;
}
</style>
