import Vue from 'vue'
import router from './router/'
import store from './store/'
// import './registerServiceWorker'
import '@/plugins/storage'
import '@/plugins/validate'
import vuetify from '@/plugins/vuetify'
import VueSweetalert2 from '@/plugins/vue-sweetalert2'
import VueTheMask from 'vue-the-mask'

import '@/assets/fonts/system-font/css/system-font.css'
import '@/theme/default.sass'
import '@/theme/pandaria.css'

import App from './App.vue'

import '@mdi/font/css/materialdesignicons.css'
Vue.prototype.store = store
// Define Global event bus
const eventBus = new Vue()
Vue.prototype.$eventBus = eventBus

Vue.config.productionTip = false
Vue.use(VueSweetalert2)
Vue.use(VueTheMask)
const app = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
})

app.$mount('#app')
