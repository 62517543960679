import axios from 'axios'
import { Tool } from '@/js/core'

const tool = new Tool()

const http = axios.create({
})

const ajax = {
  get (url, input, success, failure) {
    return axios.get(
      url,
      { params: input }
    ).then(result => {
      return resultHandler(result, Promise.resolve, Promise.reject, success, failure)
    })
  },
  post (url, input, success, failure) {
    return axios.post(
      url,
      input
    ).then(result => {
      return resultHandler(result, Promise.resolve, Promise.reject, success, failure)
    })
  },
  put (url, input, success, failure) {
    return axios.put(
      url,
      input
    ).then(result => {
      return resultHandler(result, Promise.resolve, Promise.reject, success, failure)
    })
  },
  delete (url, success, failure) {
    return axios.delete(
      url
    ).then(result => resultHandler(result, Promise.resolve, Promise.reject, success, failure))
  }
}

axios.interceptors.request.use(
  config => {
    config.headers.common['Access-Token'] = tool.getItem('AT')
    config.headers.common['L-I'] = tool.getItem('LI')
    return config
  },
  error => {
    return Promise.reject(error).catch((e) => {})
  }
)

function resultHandler (result, resolve, reject, success, failure) {
  const resultData = result.data
  if (result.status === 200) {
    if (resultData.code < 0) {
      if (failure) {
        failure(resultData)
      } else {
        if (resultData.code === -20002) {
          window.open('/#/notification/-100')
        }
      }
      return Promise.reject(result)
    } else {
      if (success) {
        success(resultData.data)
      }

      return Promise.resolve(resultData.data)
    }
  } else {
    if (failure) {
      failure(resultData)
    }

    return Promise.reject(result.statusText)
  }
}

/*******************************************************************************
 * 日期扩展类
 *
 * 1.0.0 WolfLai
 ******************************************************************************/
/**
 * 日期类型转化为指定格式字符串
 *
 * @param {Object} pattern
 *        月(M)、日(d)、12小时(h)、24小时(H)、分(m)、秒(s)、周(E)、季度(q) 可以用 1-2个占位符
 *        年(y)可以用 1-4 个占位符
 *        毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
 *        自定义格式：
 *            (new Date()).format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
 *            (new Date()).format("yyyy-MM-dd E HH:mm:ss") ==> 2009-03-10 二 20:09:04
 *            (new Date()).format("yyyy-MM-dd EE hh:mm:ss") ==> 2009-03-10 周二 08:09:04
 *            (new Date()).format("yyyy-MM-dd EEE hh:mm:ss") ==> 2009-03-10 星期二 08:09:04
 *            (new Date()).format("yyyy-M-d h:m:s.S") ==> 2006-7-2 8:9:4.18
 *        内置格式：
 *            PATTERN_SHORT_DATE = "yyyy-M-d"
 *            PATTERN_SHORT_TIME = "H:m:s"
 *            PATTERN_LONG_DATE = "yyyy-MM-dd"
 *            PATTERN_LONG_TIME = "HH:mm:ss"
 *            PATTERN_DATETIME = "yyyy-MM-dd HH:mm:ss"
 */
// eslint-disable-next-line no-extend-native
Date.prototype.format = function (pattern) {
  const o = {
    'M+': this.getMonth() + 1, // 月份
    'd+': this.getDate(), // 日
    'h+': this.getHours() % 12 === 0 ? 12 : this.getHours() % 12, // 小时
    'H+': this.getHours(), // 小时
    'm+': this.getMinutes(), // 分
    's+': this.getSeconds(), // 秒
    'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
    S: this.getMilliseconds() // 毫秒
  }

  const week = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday'
  }

  if (/(y+)/.test(pattern)) {
    pattern = pattern.replace(RegExp.$1, (String(this.getFullYear())).substr(4 - RegExp.$1.length))
  }
  if (/(E+)/.test(pattern)) {
    pattern = pattern.replace(RegExp.$1, ((RegExp.$1.length > 1) ? (RegExp.$1.length > 2 ? 'Weekend' : 'Week') : '') + week[String(this.getDay())])
  }

  for (const k in o) {
    if (new RegExp('(' + k + ')').test(pattern)) {
      pattern = pattern.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr((String(o[k])).length)))
    }
  }
  return pattern
}

export { http, ajax }
