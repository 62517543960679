import Vue from 'vue'
import VeeValidate, { Validator } from 'vee-validate'
import { validation } from './config'

const dictionary = {
  en: {
    messages: {
      required: (field) => `${field} is necessary !`
    },
    attributes: {
      account: 'Account',
      password: 'Password',
      email: 'Email',
      loanNo: 'Loan #'
    }
  }
}

// Refer to the above settings
Validator.localize(dictionary)

Validator.extend('account', validation.email)
Validator.extend('password', validation.min6)
Validator.extend('email', validation.email)
Validator.extend('onlineEmail', validation.email)
Validator.extend('supportEmail', validation.email)
Validator.extend('phone', validation.phone)
Validator.extend('fax', validation.fax)
Validator.extend('ssn', validation.ssn)
Validator.extend('accountNo', validation.bankAccountNo)
Validator.extend('routingNo', validation.bankRoutingNo)
Validator.extend('zip', validation.zip)
Validator.extend('notEmpty', validation.notEmpty)

/*
 * Event trigger rules:
 * after input in the input box, click event trigger verification.
 * The default is real-time verification
 */
Vue.use(VeeValidate)
