<template>
  <v-app id="inspire" class="app dashboard">
    <div
      style="height: 100%; width: 100%; position: absolute">
      <img class="height-full width-full m-0"
             src="../../assets/background.png"
      />
    </div>
    <div style="width: 75%; margin: auto">
      <app-toolbar class="app--toolbar"/>
        <!-- Page Header -->
        <div class="page-wrapper-pc p-t-20">
          <v-card width="75%" style="position: absolute; top: 104px; bottom: 50px" class="b-r-16">
            <v-btn v-if="page.back"
                   @click="goBack"
                   icon style="position: absolute; top: 20px; left: 120px; z-index: 100">
              <img src="../../assets/icon/back.png"
                   class="height-24"
              />
            </v-btn>
            <router-view style="height: 100%; width: 100%; position: relative" :sloth="sloth"/>
            <v-btn @click="openChat" v-if="showChat" icon style="position: absolute; top: 20px; right: 160px; z-index: 100">
              <v-badge :content="sloth.messagesNumber" :value="sloth.messagesNumber" color="error" overlap>
                <v-icon class="height-24"
                >mdi-chat-outline
                </v-icon>
              </v-badge>
            </v-btn>
<!--            <a>-->
<!--              <v-btn color="primary" icon style="position: absolute; top: 20px; right: 80px">-->
<!--                <v-icon-->
<!--                  class="height-24"-->
<!--                >email-->
<!--                </v-icon>-->
<!--              </v-btn>-->
<!--            </a>-->
            <a :href="portfolioTelephone" v-if="showPhoneBtn">
              <v-btn icon style="position: absolute; top: 20px; right: 120px">
                <v-icon
                  class="height-24"
                >phone
                </v-icon>
              </v-btn>
            </a>
            <!--            <v-btn icon style="position: absolute; top: 20px; right: 120px">-->
            <!--              <a :href="portfolioTelephone">-->
            <!--                <img src="../../assets/icon/phone.png"-->
            <!--                     class="height-24"-->
            <!--                />-->
            <!--              </a>-->
            <!--            </v-btn>-->
            <v-overlay
              absolute
              :value="overlay"
            >
              <v-icon class="mdi mdi-spin mdi-loading" large></v-icon>
            </v-overlay>
          </v-card>
        </div>
        <v-snackbar
          color="primary"
          v-model="showModal"
          top
          timeout="-1"
        >
          Congratulations! Our Pre-Underwriting Department has finalized the review of your application. To proceed with
          your {{loanAmount}} loan, simply click on the icon below.
          <template v-slot:action="{ attrs }">
            <v-btn
              text
              v-bind="attrs"
              @click="toLoanAmount"
            >
              {{ times }}s
            </v-btn>
          </template>
        </v-snackbar>
<!--        <v-snackbar dark v-model="snackbar" timeout="-1">-->
<!--          <v-card elevation="5" class="rounded-xl">-->
<!--            <v-card-title class="text-center">-->
<!--              <h3 class="f-w-700 width-full">Facts and Tips</h3>-->
<!--            </v-card-title>-->
<!--            <v-card-text>-->
<!--              Dear {{ firstName }}, did you know that many lenders base on your credit score to determine whether you're-->
<!--              pre-qualified for a loan or not? we're not saying that we do, but many other lenders do, Hold on and we-->
<!--              will-->
<!--              redirect you to the right people.-->
<!--            </v-card-text>-->
<!--          </v-card>-->
<!--          <v-card flat class="m-t-20" color="transparent">-->
<!--            <v-card-text>-->
<!--              <div-->
<!--                style="display: flex; justify-content: center; align-items: center; font-size: 16px; width: 50px; height: 50px; border-radius: 50px; border: 1px solid rgba(255, 255, 255, 0.12); margin: 0 auto;">-->
<!--                {{timer}}-->
<!--              </div>-->
<!--            </v-card-text>-->
<!--            <v-card-text class="text-center">-->
<!--              We are redirecting you to New Image Relief, wanna skip the timer?-->
<!--              click below.-->
<!--            </v-card-text>-->
<!--            <v-card-actions>-->
<!--              <v-card-text class="text-center">-->
<!--                <v-row>-->
<!--                  <v-col>-->
<!--                    <u @click="closeSnackbar">No thanks</u>-->
<!--                  </v-col>-->
<!--                  <v-col>-->
<!--&lt;!&ndash;                    <a href="https://newimagerelief.com/why-you-should-review-your-credit-report"&ndash;&gt;-->
<!--&lt;!&ndash;                       style="color: inherit;">Get Redirected Now!</a>&ndash;&gt;-->
<!--                  </v-col>-->
<!--                </v-row>-->
<!--              </v-card-text>-->
<!--            </v-card-actions>-->
<!--          </v-card>-->
<!--        </v-snackbar>-->
    </div>
  </v-app>
</template>

<script>
import AppToolbar from '../PCAppToolbar'
import { DataType, Tool } from '../../js/core'
import { Sloth } from '../../js/sloth'
import { OriginationApi } from '../../api/application'

const tool = new Tool()

export default {
  components: {
    AppToolbar
  },

  data () {
    return {
      times: 10,
      showModal: false,
      showChat: false,
      loanAmount: '',
      showPhoneBtn: true,
      options: {
        mode: 'ClientSide',
        url: '',
        wsid: ''
      },
      rightDrawer: false,
      showDrawer: true,
      sloth: {
        client: null,
        messagesNumber: JSON.parse(sessionStorage.getItem('messageNumber'))
      },
      interval: null,
      firstName: JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PERSONAL)).firstName,
      timer: 10,
      snackbar: false,
      dialogShowTime: 0,
      timeOutTimer: 0,
      dialogClose: 0
    }
  },
  computed: {
    page: {
      get () {
        return this.$store.state.application.currentPage
      }
    },
    overlay: {
      get () {
        return this.$store.state.application.overlay
      }
    },
    portfolioTelephone: {
      get () {
        return 'tel:' + this.$store.state.application.portfolioJson.telephone
      }
    }
  },
  watch: {
    'store.getters.getFromLA': {
      handler (val) {
        this.showPhoneBtn = !val
      },
      immediate: true,
      deep: true
    }
  },
  destroyed () {
    this.$eventBus.$off('showLoanAmountDialog')
    this.$eventBus.$off('verification')
    this.$eventBus.$off('startChat')
  },
  methods: {
    closeSnackbar () {
      const $this = this
      $this.snackbar = false
      window.clearInterval($this.timeOutTimer)
      window.clearInterval($this.dialogClose)
    },
    countDown () {
      const $this = this
      $this.interval = setInterval(function () {
        if ($this.times > 0) {
          --$this.times
        } else {
          clearInterval($this.interval)
          $this.toLoanAmount()
        }
      }, 1000)
    },
    goBack () {
      this.closeSnackbar()
      this.$router.push(DataType.PAGES[this.page.previous].addressPC)
    },
    toLoanAmount () {
      this.showModal = false
      clearInterval(this.interval)
      this.$router.push('/wap/option')
    },
    initWebSocket () {
      const $this = this
      const wsid = localStorage.getItem(DataType.COOKIE_KEY.WSID)
      if (tool.isNotEmpty(wsid)) {
        this.options.wsid = wsid
        this.sloth.client = new Sloth(this.options)
        this.$eventBus.$emit('initSloth')
        this.sloth.client.init()
        this.sloth.client.on('message', function (message) {
          message = message.data
          switch (parseInt(message.ticket)) {
            case DataType.LoanActionEnum.FORCE_QUIT.value: {
              $this.$router.push('/instant/notification/-100')
              $this.$eventBus.$emit('forceQuit', 'forceQuit')
              break
            }
            default: {
              break
            }
          }
        })
      }
    },
    initTimeOut () {
      const $this = this
      const portfolioId = localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
      const page = $this.$store.state.application.currentPage
      if (tool.isEmpty(page.popup) || !page.popup) {
        return false
      }
      if (tool.isEmpty(portfolioId) ||
        DataType.PortfolioEnum.WEST_SIDE_LENDING.value === parseInt(portfolioId) ||
        DataType.PortfolioEnum.EAST_LINE_LENDING.value === parseInt(portfolioId)
      ) {
        return false
      }
      $this.dialogShowTime = 5 * 60
      document.body.addEventListener('keydown', function () {
        $this.dialogShowTime = 5 * 60
      })
      document.body.addEventListener('mousedown', function () {
        $this.dialogShowTime = 5 * 60
      })
      $this.timeOutTimer = window.setInterval(function () {
        if ($this.dialogShowTime > 0) {
          $this.dialogShowTime -= 1
        } else {
          window.clearInterval($this.timeOutTimer)
          $this.timer = 10
          $this.snackbar = true
          $this.dialogClose = setInterval(function () {
            if ($this.timer > 0) {
              $this.timer -= 1
            } else {
              window.clearInterval($this.dialogClose)
              $this.snackbar = false
              // window.location.href = 'https://newimagerelief.com/why-you-should-review-your-credit-report'
            }
          }, 1000)
        }
      }, 1000)
    }
  },
  created () {
    const _this = this
    // setTimeout(function () {
    //   _this.initTimeOut()
    // }, 500)
    const ip = localStorage.getItem('IP')
    if (tool.isEmpty(ip)) {
      OriginationApi.getIp(function (result) {
        localStorage.setItem('IP', result.ip)
      })
    }
    if (!tool.isPC()) {
      setTimeout(function () {
        const page = _this.$store.state.application.currentPage
        _this.$router.push(page.address)
      }, 300)
    }
    const protocol = window.location.protocol === 'http:'
    if (protocol) {
      this.options.url = process.env.VUE_APP_WSXCH_URL
    } else {
      this.options.url = process.env.VUE_APP_WSXCH_DNS
    }
    this.initWebSocket()
    this.$eventBus.$on('showLoanAmountDialog', (loanAmount) => {
      if (this.$store.state.application.pageCount < 7) {
        this.loanAmount = loanAmount
        this.showModal = true
        this.countDown()
      }
    })
    this.$eventBus.$on('verification', () => {
      this.initWebSocket()
    })
    _this.$vuetify.theme.themes.light.primary = '#00234B'

    _this.$eventBus.$on('closeCountDown', () => {
      window.clearInterval(_this.dialogShowTime)
      window.clearInterval(_this.timeOutTimer)
    })
    // _this.$eventBus.$on('initTimeOut', () => {
    //   window.clearInterval(_this.timeOutTimer)
    //   _this.initTimeOut()
    // })
  }
}
</script>

<style lang="sass" scoped>
  .setting-fab
    top: 50% !important
    right: 0
    border-radius: 0

    .v-application--wrap
</style>
<style lang="css">
  .v-toolbar__content, .v-toolbar__extension {
    padding: 0px;
  }
</style>
