import { DataType, Tool } from './core'
const tool = new Tool()

let isReady = false
let socket = null
let heart = null
const defaults = {
  url: 'localhost',
  mode: 'ClientSide',
  wsid: 'CREATE',
  ping: { ticket: 'PING' }
}
/**
 * Create A Websocket Object
 */
export function Sloth (options) {
  this.initOptions(options)
}

/**
 * Extend the prototype
 * @type {{constructor: Sloth, initOptions: Sloth.initOptions}}
 */
Sloth.prototype = {
  constructor: this,
  initOptions: function (options) {
    this.defaults = extend(defaults, options, true)
    this.eventListeners = []
    this.eventHandlers = {}
  },
  init: function () {
    initWebsocket(this)
    return this
  },
  on: function (type, handler) {
    if (typeof this.eventHandlers[type] === 'undefined') {
      this.eventHandlers[type] = []
    }
    this.eventListeners.push(type)
    this.eventHandlers[type].push(handler)
    return this
  },
  off: function (type, handler) {
    if (this.eventHandlers[type] instanceof Array) {
      const eventHandlers = this.eventHandlers[type]
      const length = eventHandlers.length
      let i = 0
      for (i; i < length; ++i) {
        if (eventHandlers[i] === handler) {
          break
        }
      }
      this.eventListeners.splice(i, 1)
      eventHandlers.splice(i, 1)
      return this
    }
  },
  status: function () {
    return socket.readyState
  },
  sendMessage: function (message) {
    if (isReady && socket != null) {
      socket.send(message)
    }
  },
  triggerEvent: function (type, data) {
    if (this.eventHandlers[type] instanceof Array) {
      const eventHandlers = this.eventHandlers[type]
      for (let i = 0, length = eventHandlers.length; i < length; ++i) {
        eventHandlers[i]({
          type: type,
          target: this,
          data: data
        })
      }
      return true
    }
    return false
  }
}

/**
 * Init websocket and Build Connection
 */
function initWebsocket (wsxch) {
  if ('WebSocket' in window) {
    const protocol = window.location.protocol === 'http:' ? 'ws' : 'wss'
    if (socket !== null) {
      socket.close()
    }
    if (wsxch.defaults.wsid === '' || wsxch.defaults.wsid === null) {
      wsxch.defaults.wsid = 'CREATE'
    }
    socket = new WebSocket('{0}://{1}/v3/wsxch/{2}'.format(
      protocol,
      wsxch.defaults.url,
      wsxch.defaults.wsid
    ))
    socket.onopen = function () {
      isReady = true
      wsxch.triggerEvent('open')
    }
    socket.onerror = function () {
      wsxch.triggerEvent('error')
    }
    socket.onclose = function () {
      wsxch.triggerEvent('close')
    }
    socket.onmessage = function (event) {
      const data = JSON.parse(event.data)
      if (data.ticket === 'WSID') {
        heartbeat(wsxch)
      }
      wsxch.triggerEvent('message', data)
    }
    window.onbeforeunload = function () {
      socket.close()
      clearInterval(heart)
      heart = null
    }
  }
}

function heartbeat (wsxch) {
  defaults.ping.destination = defaults.wsid
  heart = setInterval(function () {
    if (socket.readyState === 1) {
      const destination = localStorage.getItem(DataType.COOKIE_KEY.DESTINATION)
      if (tool.isNotEmpty(destination)) {
        defaults.ping.destination = destination
        defaults.ping.wsid = localStorage.getItem(DataType.COOKIE_KEY.WSID)
      }
      socket.send(JSON.stringify(defaults.ping))
    } else if (socket.readyState === 3) {
      clearInterval(heart)
      initWebsocket(wsxch)
    }
  }, 30 * 1000)
}

function extend (target, data, override) {
  for (const key in data) {
    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty(key) && (!target.hasOwnProperty(key) || override)) {
      target[key] = data[key]
    }
  }
  return target
}

// eslint-disable-next-line no-extend-native
String.prototype.format = function (args) {
  let result = this
  if (arguments.length > 0) {
    if (arguments.length === 1 && typeof (args) === 'object') {
      for (const key in args) {
        if (args[key] !== undefined) {
          const reg = new RegExp('({' + key + '})', 'g')
          result = result.replace(reg, args[key])
        }
      }
    } else {
      for (let i = 0; i < arguments.length; i++) {
        if (arguments[i] !== undefined) {
          const reg = new RegExp('({)' + i + '(})', 'g')
          result = result.replace(reg, arguments[i])
        }
      }
    }
  }
  return result
}
